.MuiPickerDTTabs-tabs,
.MuiPickersClockPointer-pointer,
.MuiPickersToolbar-toolbar,
.MuiPickersDay-daySelected {
  background: #000 !important;
}

.MuiButton-textPrimary {
  color: #000 !important;
}
.MuiPickersClockPointer-noPoint,
.MuiPickersClockPointer-thumb {
  border-color: #000 !important;
}

/* Date range picker overrides */
/* Make icon lead */
.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  flex-direction: row-reverse;
}
.MuiInputAdornment-positionEnd {
  order: -1;
}

/* inputs */
.MuiInput-underline::after,
.MuiInput-underline::before {
  display: none !important;
}
