* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	text-decoration: none;
	outline: none;
	border: none;
	font-family: "Poppins", "Open Sans", sans-serif;
	font-family: "Noto Sans JP", sans-serif;
}
html {
	font-family: "Poppins", pt-sans, sans-serif;
	font-family: "Noto Sans JP", sans-serif;
	font-weight: 400;
	font-style: normal;
}
h1 {
	max-width: 20ch;
	font-size: 48px;
	margin-bottom: 2rem;
	font-family: "Open Sans Condensed", sans-serif;
}
h2 {
	margin-bottom: 8px;
}
p {
	opacity: 0.7;
	margin-bottom: 8px;
}

a {
	color: #000;
}
.minor {
	font-size: 12px;
}
.medium {
	font-size: 18px;
}
.wrapper {
	height: 100vh;
	overflow: hidden;
}
.nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding: 10vh 12vw;
}
.nav--bottom {
	position: fixed;
	bottom: 0;
	width: 100vw;
	z-index: 999;
	background-color: rgba(255, 255, 255, 0.801);
	z-index: 1;
}
.nav--bottom::before {
	content: "";
	margin-top: -8px;
	margin-left: -16px;
	height: 100%;
	border-radius: 4px;
	position: absolute;
	width: calc(100% - 32px * 1.2);
	background-color: rgba(0, 0, 0, 0.062);

	z-index: -1;
}
.nav > * {
	display: flex;
	gap: 1rem;
}
.nav__links {
	display: flex;
	align-items: center;
}
.nav__link {
	display: grid;
	opacity: 0.86;
	font-size: 14px;
	justify-content: center;
	align-items: center;
	place-items: center;
	transition: 400ms all;
}
.icon {
	width: 24px;
	height: 24px;
}
.nav__link--active {
	opacity: 1;
}
.link--primary {
	background-color: rgba(0, 0, 0, 0.397);
	color: #fff;
	padding: 0.8rem 1.6rem;
}
.hero {
	padding: 4rem 0;
}
.hero__body {
	padding-left: 28px;
	border-left: 2px solid;
}
.body-text {
	max-width: 40ch;
	line-height: 220%;
}
.container {
	--hr-padding: 12vw;
	position: relative;
	width: 100%;
	display: grid;
	padding: 8vh var(--hr-padding);
	color: #000;
	overflow-x: hidden;
}
.container--primary {
	background-color: #000;
	gap: 2rem;
}
.container--primary::before {
	content: "";
	position: absolute;
	width: 2px;
	height: 2rem;
	left: 14vw;
	top: 1rem;
	background-color: #fff;
}
.col {
	display: flex;
}
.container--primary > * {
	color: #fff;
}
.container__body {
	margin-top: 4rem;
	color: #fff;
}

.container--overlap {
	--w: calc(100% - var(--hr-padding) + 2rem);
	position: absolute;
	margin-left: 50vw;
	transform: translateX(-50%) translateY(-50%);
	width: var(--w);
	/* min-height: 16vh;
	max-height: 120px; */
	padding: 0;
	display: flex;
	padding: 16px;
	overflow-x: auto;
	overflow-y: auto;
	background-color: #fff;
	z-index: 10;
}
.container--overlap + .container {
	padding-top: 20vh;
}
.form--primary {
	width: 100%;
	display: grid;
	grid-template-columns: 2fr 2fr 1fr;
	gap: 1rem;
	height: 100%;
}
.form--auth {
	--w: 40vw;
	--p: 2rem;
	position: relative;
	width: var(--w);
	height: max-content;
	background-color: #fff;
	text-align: left;
	padding: var(--p);
	overflow: hidden;
}
.form--inline {
	display: flex;
	width: 70vw;
	min-height: 3rem;
	text-align: left;
	margin: 1.5rem 0 2rem;
	justify-content: space-between;
	border-radius: 8px;
	box-shadow: 0 0 18px rgba(0, 0, 0, 0.438);
}
.form--inline > * {
	height: 100% !important;
	border-radius: 8px;
}
.form--inline .input {
	padding: 1.1rem;
	height: 100%;
	width: 110%;
	margin-right: -8px;
}
form section {
	display: grid;
	gap: 1rem;
}
form label {
	color: #000;
}
.form--primary .btn--primary {
	height: 100%;
}

.input-group {
	display: grid;
	width: 100%;
	color: rgba(0, 0, 0, 0.644);
	border: 2px solid currentColor;
}
.form--primary .input-group:first-of-type {
	/* width: max-content; */
}
.input-group label {
	margin-left: 1rem;
	margin-top: -0.5rem;
	background-color: #fff;
	width: max-content;
	padding: 0 4px;
	transform: scale(0.9);
	color: inherit;
}
.input-group .inputs {
	display: flex;
	gap: 1rem;
}
.divider {
	width: 100px;
	background-color: #000;
	margin: 8px;
	z-index: 100;
}
.divider--vert {
	width: 2px;
	height: 90%;
	margin: auto 8px;
	margin-top: -6px;
	align-self: center;
}

.btn {
	outline: none;
	border: 2px solid rgba(44, 44, 44, 0.162);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem 2rem;
	font-family: inherit;
	font-size: 14px;
	cursor: pointer;
	color: rgba(0, 0, 0, 0.753);
	flex-basis: 50%;
}

.btn--primary {
	background-color: #000;
	color: #fff;
}

.overflow-wrapper {
	width: calc(100vw + var(--hr-padding) * 2);
	margin-left: calc(var(--hr-padding) * -1);
	padding: 1rem;
	padding-left: var(--hr-padding);
	overflow-x: auto;
}

.card {
	position: relative;
	width: 40vh;
	height: 40vh;
	padding: 2rem;
	display: grid;
	align-items: end;
	z-index: 1;
	cursor: pointer;
	transition: all 400ms;
}
.card:hover {
	transform: scale(1.02);
	z-index: 1;
}
.card > * {
	/* outline: 1px solid; */
}
.list:hover .card:not(:hover) {
	filter: grayscale(1) brightness(0.5);
}
.card > * {
	z-index: 2;
	color: #000;
}
.card::before {
	position: absolute;
	content: "";
	width: 100%;
	height: 100%;
	background-image: linear-gradient(transparent, #fff);
	background-color: rgba(255, 255, 255, 0.164);
	z-index: 0;
}
.card-details {
	display: grid;
	gap: 8px;
	width: 100%;
	height: max-content;
}
.img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.img--thumb {
	width: 4rem;
	border-radius: 4px;
}
.img--bg {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}
.img--header {
	opacity: 0.4;
}

.list--overflow {
	display: grid;
	grid-auto-flow: column;
	gap: 1rem;
	justify-content: start;
	transition: all 400ms;
}
.list--vert {
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
}
.list--vert .card {
	width: 100%;
}
.list--grid {
	display: flex;
	grid-template-columns: repeat(4, minmax(1fr, 100px));
	width: calc(100vw - 24vw) !important;
	flex-wrap: wrap;
	gap: 1rem;
}
.banner {
	width: calc(100vw - 24vw);
	height: 100%;
	display: flex;
	padding: 20vh;
	background-color: rgba(255, 255, 255, 0.05);
}
@media (max-width: 867px) {
	* {
		/* outline: 1px solid green; */
	}
	.nav {
		padding: 16px 32px;
	}
	.container {
		height: 100vh;
		background-color: rgb(250, 250, 250);
	}
	.card {
		border-radius: 8px;
		overflow: hidden;
	}
	.card img {
		opacity: 1;
	}
	.card::before {
		opacity: 0;
	}
	.card-details {
		position: relative;
		width: 100%;
		height: max-content;
		z-index: 1;
	}
	.card-details::before {
		content: "";
		width: calc(100% + 4rem);
		height: calc(100% + 4rem);
		top: -2rem;
		left: -2rem;
		border-radius: 8px;
		position: absolute;
		background-color: rgba(255, 255, 255, 0.856);
		z-index: -1;
	}
}

.map {
	position: absolute;
	width: 100%;
	height: 100vh;
	z-index: 0;
}
.map + * {
	z-index: 2;
}
/* REGISTRATION */
.form--auth {
	position: relative;
	padding: 2rem;
	flex-shrink: 0;
}
.stages {
	display: grid;
	width: min-content;
	grid-template-columns: repeat(2, calc(40vw - 4rem));
	gap: 4rem;
	transition: 400ms all;
}
.stage {
	display: grid;
	width: calc(var(--w) - 4rem);
	gap: 1rem;
}

.at-stage-2 .stages {
	transform: translateX(calc(-1 * var(--w)));
}
.at-stage-2 .stage--1 {
	opacity: 0;
	transition: 0.4s;
}
/* .stage {
	opacity: 0;
	width: calc(var(--w) - 4rem);
	min-width: calc(var(--w) - 4rem);
	transition: 0.2s;
	z-index: 1;
} */
/* .at-stage-1 .stage--1 {
	opacity: 1;
	z-index: 100;
}
.at-stage-2 .stage--2 {
	opacity: 1;
	transform: translateX(calc(-100% - 2rem));
	z-index: 100;
}
.at-stage-2 .stage--1 {
	transform: translateX(calc(-100% - 2rem));
} */
/* .stage {
	margin: 0;
	width: calc(var(--w) - var(--p) * 2);
	max-width: calc(var(--w) - var(--p) * 2);
	transition: 0.2s;
}
.at-stage-1 .stage--1 {
	margin-left: 9rem;
	background-color: red;
} */

/* PLACES SUGGESTOR */
.places-suggestor {
	display: grid;
}
.places-suggestor {
	transition: 0.2s;
	animation: 0.2s fade fade-out;
}
.suggestion-item {
	color: #fff;
	padding: 1rem;
}
.suggestion-item--active {
	background-color: rgb(22, 22, 22);
}
@keyframes fade {
	from {
		opacity: 0;
		transform: translateY(-1rem);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
.location-search-input {
	padding: 1rem;
}
.suggestion-item {
	background-color: #000;
}
/* DASHY PAGE */
.dashy {
	--list-width: 24vw;
	--padding: 2rem;
	--soft: #f4f4f417;

	display: grid;
	grid-template-columns: 2fr 8fr;
	padding: 2rem;
	overflow: hidden;
	height: 100vh;

	background-color: #000;
	color: #fff;
}
.dashy__top-bar {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	gap: 2rem;
	z-index: 10;
	transform: scale(1);
}
.dashy__top-bar .group {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.dashy__top-bar .group:first-child {
	padding: 0 var(--padding);
	width: var(--list-width);
}
.group:not(:first-child) {
	flex: 1;
}
.input--dashy-search {
	background-color: var(--soft);
	text-indent: 1rem;
	padding: 0.8rem 0.5rem;
}
.profile-thumb {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-template-rows: 1fr;
	height: 3rem;
	gap: 1rem;
	font-size: 14px;
	align-items: center;
}
.profile-thumb .text {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}
.profile-thumb img {
	border: none;
	outline: none;
}
.text .name {
	color: rgba(255, 255, 255, 0.74);
}
.text .plan {
	color: rgba(255, 255, 255, 0.397);
}

.sidebar {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.sidebar__logo {
	display: flex;
	align-items: center;
	width: max-content;
	color: #fff;
	gap: 1rem;
}
.sidebar__links {
	display: grid;
}
.sidebar__link {
	opacity: 0.67;
	color: #fff;
}
.sidebar__link--active {
	opacity: 1;
}
.sidebar__link {
	display: flex;
	align-items: center;
	padding: 1.2rem 0;
	gap: 1.5rem;
}
.icon {
	width: 1rem;
	height: 1rem;
	background-color: var(--soft);
}
.bg {
	padding-top: 4rem;
	padding: 2rem;
	height: 10vh;
	width: calc(100% - 2rem);
}
.dashy__main {
	position: relative;
	overflow-y: auto;
	margin-top: calc(var(--padding) * -1);
	width: calc(100% + var(--padding));
	height: calc(100% + calc(var(--padding) * 2));

	padding-top: var(--padding);
	padding-right: var(--padding);
}
.dashy__list {
	width: var(--list-width);
	height: calc(100vh - 1rem);
	padding-top: calc(var(--padding) * 2);
	background-color: var(--soft);
	border-radius: 4px;
	margin-top: -4rem;
	overflow: hidden;
}
.card-list {
	display: grid;
	gap: 1rem;
	margin: 0 0 2rem;
	padding-top: calc(var(--padding));
}
.fancy-card {
	position: relative;
	background-color: var(--soft);
	margin: 0 auto;
	width: 90%;
	min-height: 20vh;
	padding: 1rem;
}
.fancy-card button {
	align-self: end;
}

/* DIALOG */

.dialog {
	top: 0;
	left: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.74);
	opacity: 0;
	display: grid;
	z-index: -10;
}
.dialog[open] {
	opacity: 1;
	z-index: 10;
}
.dialog[open] ~ * {
	filter: blur(1px) brightness(0.6);
}
.dialog__inner {
	position: relative;
	background-color: #fff;
	width: 80vw;
	height: 80vh;
	padding: var(--padding);
	place-self: center;
}
.btn--close {
	padding: 0.5rem;
	right: 2rem;
	bottom: 2rem;
	background-color: #000;
	color: #fff;
	position: absolute;
}
/* TAB PAGER */
.tab-pager {
	display: flex;
	margin-top: var(--padding);
	padding: 0 var(--padding);
	width: 100%;
	gap: 1rem;
}
.tab-pager input {
	display: none;
}
.tab-pager label {
	opacity: 0.67;
	transform: scale(0.8);
	cursor: pointer;
	transition: 0.2s;
}

.tab-pager input:checked + label {
	opacity: 1;
	transform: scale(1);
}
/* ACTIVITIES AND CHART */
.dashy__activities,
.dashy__calender {
	position: absolute;
	top: calc(var(--padding) * 3);
	right: var(--padding);
	width: calc(100% - var(--list-width) - var(--padding));
	height: calc(50% - var(--padding) * 2);
	padding: 0.5rem var(--padding);
	border-radius: 4px;
}
.dashy__calender {
	top: unset;
	bottom: var(--padding);
}
.chart {
	width: 100%;
	height: calc(100% - 4rem);
	background-color: var(--soft);
	margin: 2rem 0;
	border-radius: 4px;
}
/* ABSTRACT */
.flex {
	display: flex;
	width: 100%;
}
/* LOGIN & REGISTER PAGE */
.page--auth {
	background-color: #000;
	height: 100vh;
	overflow: hidden;
	display: grid;
	padding: var(--padding) 8rem;
}
.form--auth {
	justify-self: center;
}
.form--auth h1 {
	margin-bottom: 1rem;
}
.form--auth p {
	margin-bottom: 3rem;
}
.btn--logout {
	background-color: var(--soft);
	border-radius: 4px;
	cursor: pointer;
	border: 2px solid transparent;
	transition: 0.2s;
}
.input--dashy-search {
	transition: 0.2s;
	color: #f4f4f4;
	border: 2px solid transparent;
	border-radius: 4px;
}
.input--dashy-search:hover,
.btn--logout:hover {
	background: rgb(19, 19, 19);
	border: 2px solid rgb(82, 82, 82);
}
.chart {
	position: relative;
}
.lame-banner {
	position: absolute;
	color: #f4f4f4;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0.56;
}
.auth--bg {
	position: absolute;
	opacity: 0.26;
	filter: blur(1px);
}
